import axios from "axios";
import { rule5properties } from "../../../properties";

class MonitorService {
  async getUserMessageChannels() {
    // Get available user message channels.
    try {
      const userMessageChannelResponse = await axios.get(
        rule5properties.userMessageChannels + "?system=Slack"
      );
      if (
        userMessageChannelResponse.data &&
        Array.isArray(userMessageChannelResponse.data)
      ) {
        return userMessageChannelResponse.data;
      } else {
        throw new Error("Invalid user message channels response.");
      }
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  async getProcessedDefaultValues(
    formDefaults,
    modAgent,
    setUserMessageChannels
  ) {
    // Pre-selected signals in the agent.
    const defaultSignals = modAgent?.signals
      ? modAgent.signals.map((signal) => {
          return { signalName: signal, checked: true };
        })
      : [];
    const modAgentUserChannels =
      modAgent?.action?.agentActionInfo?.notifications?.slackChannels;
    const defaultValues = { ...formDefaults };
    // Get available signals.
    const signalResponse = await axios.get(
      rule5properties.values + "?name=Themes"
    );
    if (signalResponse.data?.values) {
      const newDefaultSignals = signalResponse.data.values.map((signal) => {
        return {
          signalName: signal,
          checked: defaultSignals.find((el) => signal === el.signalName)
            ? true
            : false,
        };
      });
      defaultValues.signals = newDefaultSignals;
    }
    // Pre-selected regions in the agent.
    const defaultRegions = modAgent?.regions
      ? modAgent.regions.map((signal) => {
          return { regionName: signal, checked: true };
        })
      : [{ regionName: "North America", checked: true }];
    // Get available regions.
    const regionResponse = await axios.get(
      rule5properties.values + "?name=Regions"
    );
    if (regionResponse.data?.values) {
      const newDefaultRegions = regionResponse.data.values.map((region) => {
        return {
          regionName: region,
          checked: defaultRegions.find((el) => region === el.regionName)
            ? true
            : false,
        };
      });
      defaultValues.regions = newDefaultRegions;
    }
    const userMessageChannels = await this.getUserMessageChannels();
    setUserMessageChannels(userMessageChannels);
    if (modAgentUserChannels?.length > 0) {
      const convertedMessageChannels = userMessageChannels.filter(
        (msgChannel) => modAgentUserChannels.includes(msgChannel.id)
      );
      defaultValues.action.agentActionInfo.notifications.slackChannels =
        convertedMessageChannels;
    }
    return defaultValues;
  }
}

export default new MonitorService();
