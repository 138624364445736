import { Box, IconButton, Stack, Typography } from "@mui/material";
import { rule5properties } from "../../properties";
import { useUser } from "../../context/UserContext";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { WhiteBorderlessButton } from "../../common/StyledComponents";
import { useDialog } from "../../context/DialogContext";
import ConfigureSlackChannel from "../../modal/ConfigureSlackChannel";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import outreachLogo from "../../res/outreach_logo.webp";
import JwtService from "../../user/JwtService";

export function OutreachIntegration(props) {
  const { configuredOutreachConnection, setConfiguredOutreachConnection } =
    props;
  const userContext = useUser();

  return (
    <Box
      sx={{
        backgroundColor: "#f2f3f2",
        padding: "20px",
        height: "100%",
        borderRadius: "12px",
      }}
    >
      <Typography variant="h5" sx={{ width: "100%", mb: 2, fontWeight: 500 }}>
        Outreach
      </Typography>
      {configuredOutreachConnection ? (
        <>{"Success have Outreach details"}</>
      ) : (
        // <SlackConfiguration
        //   configuredSlackChannels={configuredOutreachConnection}
        //   setConfiguredOutreachConnection={setConfiguredOutreachConnection}
        // />
        <AddToOutreachButton email={userContext.email} />
      )}
    </Box>
  );
}

function SlackConfiguration(props) {
  const { configuredSlackChannels, setConfiguredOutreachConnection } = props;

  const dialog = useDialog();

  function deleteChannelConfig(index) {
    let newChannelConfigList = [...configuredSlackChannels];
    newChannelConfigList.splice(index, 1);
    axios
      .delete(
        rule5properties.userMessageChannels +
          "/" +
          configuredSlackChannels[index].id
      )
      .then((res) => {
        if (res.status === 204) {
          setConfiguredOutreachConnection(newChannelConfigList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Stack>
      <Typography variant="settingsHeader" sx={{ mb: 1 }}>
        Configured Channels
      </Typography>
      {configuredSlackChannels.map((channelConfig, index) => (
        <SlackChannelRow
          config={channelConfig}
          index={index}
          configClicked={() => {
            dialog.openModal(
              "Configure Slack Channel",
              ConfigureSlackChannel,
              {
                existingList: configuredSlackChannels,
                modIndex: index,
                modConfig: channelConfig,
                savedCallback: (newList) => {
                  setConfiguredOutreachConnection(newList);
                },
              },
              "sm"
            );
          }}
          deleteChannelConfig={deleteChannelConfig}
        />
      ))}
      <WhiteBorderlessButton
        sx={{
          mt: 1,
          px: 2.25,
          py: 1.5,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onClick={() => {
          dialog.openModal(
            "Configure Slack Channel",
            ConfigureSlackChannel,
            {
              existingList: configuredSlackChannels,
              savedCallback: (newList) => {
                setConfiguredOutreachConnection(newList);
              },
            },
            "sm"
          );
        }}
      >
        <Typography sx={{ fontSize: "14px", opacity: 0.7 }}>
          Add new...
        </Typography>
        <PlaylistAddIcon fontSize="small" sx={{ opacity: 0.7 }} />
      </WhiteBorderlessButton>
    </Stack>
  );
}

function SlackChannelRow(props) {
  const { config, configClicked, index, deleteChannelConfig } = props;
  return (
    <WhiteBorderlessButton
      sx={{ py: 1, px: 2, direction: "row", alignItems: "center", flexGrow: 1 }}
      onClick={configClicked}
    >
      <ChatBubbleIcon fontSize="small" sx={{ opacity: 0.5 }} />
      <Typography
        sx={{ flexGrow: 1, mx: 2, mb: 0.25, opacity: 0.7 }}
        textAlign="left"
      >
        # {config.channelName}
      </Typography>
      <IconButton
        disableRipple
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          deleteChannelConfig(index);
        }}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </WhiteBorderlessButton>
  );
}

const fetchData = async () => {
  try {
    fetch(rule5properties.outreachLogin, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JwtService.getToken(),
        // Accept: "text/event-stream",
      },
    })
      .then((response) => {
        if (response) {
          // Handle the redirect
          window.location.href = response.data.redirectUrl;
        } else {
          // Process the response normally
          return response.json();
        }
      })
      .then((data) => {
        // Handle the data
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    // const response = await fetch(rule5properties.outreachLogin, {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + JwtService.getToken(),
    //     // Accept: "text/event-stream",
    //   },
    //   // body: JSON.stringify(params),
    //   // signal,
    // });
    // if (!response.ok) {
    //   throw new Error(`HTTP error! status: ${response.status}`);
    // }
    // const data = await response.json();
    // // Handle the redirect URL here
    // window.location.href = data.redirectUrl;
  } catch (error) {
    console.error("Fetch error:", error);
  }
};

function AddToOutreachButton(props) {
  const { email } = props;
  return (
    <button
      onClick={() => {
        // fetchData();
        axios.get(rule5properties.outreachLogin).then((res) => {
          window.location.href = res.data.redirectUrl;
        });
      }}
      // href={rule5properties.outreachLogin}
      style={{
        alignItems: "center",
        color: "black",
        backgroundColor: "rgb(222, 224, 231)",
        border: "0",
        borderRadius: "4px",
        display: "inline-flex",
        fontFamily: "Lato, sans-serif",
        fontSize: "16px",
        fontWeight: "600",
        height: "48px",
        justifyContent: "center",
        textDecoration: "none",
        width: "236px",
        cursor: "pointer",
      }}
    >
      <img
        src={outreachLogo}
        alt="outreach_logo"
        style={{ height: "20px", width: "20px", marginRight: "12px" }}
      />
      Add to Outreach
    </button>
  );
}
