import React, { useEffect } from "react";
import Workspace from "./Workspace";
import OpportunityList from "./opportunity/OpportunityList";
import OpportunityWindow from "./opportunity/OpportunityWindow";
import { useLocation, Switch, Route, useHistory } from "react-router-dom";
import Toolbar from "../common/Toolbar";
import { makeStyles } from "@mui/styles";
import {
  Stack,
  Divider,
  Fade,
  Button,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link as MuiLink } from "@mui/material";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ShareIcon from "@mui/icons-material/Share";
import { useDialog } from "../context/DialogContext";
import ShareOpportunity from "../modal/ShareOpportunity";
import axios from "axios";
import { rule5AppUrl, rule5properties } from "../properties";
import {
  createUrlSearchParams,
  useDisplayContext,
} from "../context/DisplayContext";
import SFLanding from "./opportunity/SFLanding";
import rule5logo from "../res/r5_logo.svg";
import { throttle } from "lodash";
import ForumIcon from "@mui/icons-material/Forum";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import PreviousNextPicker from "../common/PreviousNextPicker";

const useStyles = makeStyles(() => ({
  title: {
    display: "flex",
    flexDirection: "row",
    userSelect: "none",
  },
  titleButton: {
    opacity: 1,
    transition: "0.5s",
  },
  titleButtonInOpp: {
    cursor: "pointer",
    opacity: 0.5,
    transition: "0.2s",
    "&:hover": {
      opacity: 1,
      color: "rgb(25, 118, 210)",
      marginLeft: "-4px",
    },
  },
  chevronBreadcrumb: {
    display: "block",
    transition: "0.2s",
  },
}));

export const OpListContext = React.createContext();

export default function WSOpportunities() {
  // For nav bar details on scrolldown
  const [opportunity, setOpportunity] = React.useState(null);
  const [showNavBarDetails, setShowNavBarDetails] = React.useState(false);
  const [inOpportunity, setInOpportunity] = React.useState(false);
  const [listFilter, setListFilter] = React.useState({
    items: [],
    quickFilterValues: [],
  });
  const [consecutiveOpportunity, setConsecutiveOpportunity] = React.useState({
    prev: null,
    next: null,
  });

  const [oppButtonHover, setOppButtonHover] = React.useState(false);
  const [showOpportunityTools, setShowOpportunityTools] = React.useState(true);

  /* Loads rows from parent container for the opportunity list. Greatly improves performance when navigating
   * back to list from an opportunity. */
  const [rows, setRows] = React.useState([]);
  const [rowsLoaded, setRowsLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [totalRowCount, setTotalRowCount] = React.useState(0);

  const [paginationModel, setPaginationModel] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState("");
  const throttleId = React.useRef();

  // Stale is true if the list needs to be reloaded.
  const [rowsStale, setRowsStale] = React.useState(true);

  // For navigating opportunities from within opportunity using prev / next
  const [sortedRows, setSortedRows] = React.useState([]);

  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const dialog = useDialog();

  const displayContext = useDisplayContext();

  useEffect(() => {
    // Used to ensure rows will not be loaded into the table from an outdated throttled load.
    const randId = Math.random();
    throttleId.current = randId;
    if (paginationModel) {
      loadPaginatedRows(
        paginationModel.page,
        paginationModel.pageSize,
        searchValue,
        randId
      );
    }
  }, [paginationModel, searchValue]);

  const loadPaginatedRows = React.useMemo(
    () =>
      throttle((pageIndex, pageSize, searchValue, expectedThrottleId) => {
        setLoading(true);
        let paginationParams = "?pageIndex=0&pageSize=10";
        let searchParams = "";
        let companyParams = "";
        if (searchValue.trim().length > 0) {
          searchParams = `searchValue=${searchValue}`;
        }
        if (pageIndex !== undefined && pageSize !== undefined) {
          paginationParams = `${
            searchParams.length > 0 ? "&" : ""
          }pageIndex=${pageIndex}&pageSize=${pageSize}`;
        }
        // if (companyId) {
        //   searchParams = `companyId=${searchValue}`;
        // }
        const composedParams = "?" + searchParams + paginationParams;
        axios
          .get(rule5properties.getOpportunityList + composedParams)
          .then((response) => {
            let opsData = response.data;
            if (opsData.results && Array.isArray(opsData.results)) {
              // Prevent set state from activating unless it's the latest call to the throttled function.
              if (expectedThrottleId === throttleId.current) {
                // Validate content
                setRows(opsData.results);
                setRowsLoaded(true);
                setTotalRowCount(opsData.total);
                setLoading(false);
              }
            } else {
              // API call failed
              console.log("Error occured retrieving opportunities list.");
              setLoading(false);
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.status);
              console.log(error.response.data);
              console.log("Error occured retrieving opportunities list.");
            }
            setLoading(false);
          });
      }, 1000),
    []
  );

  useEffect(() => {
    axios
      .get(rule5properties.getUserOpportunityConfigs)
      .then((response) => {
        // let columns=JSON.parse(response.data);
        let data = response.data;
        if (data !== {}) {
          if (data.pageSize) {
            setPaginationModel({ page: 0, pageSize: data.pageSize });
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.status);
          console.log(error.response.data);
        }
      });
  }, []);

  // Calls API to load opportunity list.
  useEffect(() => {
    if (rowsStale) {
      if (paginationModel) {
        loadPaginatedRows(
          paginationModel.page,
          paginationModel.pageSize,
          searchValue,
          throttleId.current
        );
      }
      setRowsStale(false);
    }
  }, [rowsStale]);

  useEffect(() => {
    if (!isNaN(parseInt(location.pathname.split("/")[3]))) {
      /* User is in an opportunity - check to see if we've clicked in from the list, and have a previous and next
       * opportunity to navigate to. */
      setInOpportunity(true);
      if (sortedRows?.length > 0) {
        let currIndex = 0;
        // Find our position in the list of opportunities.
        sortedRows.some((opp, index) => {
          if (opp.id === parseInt(location.pathname.split("/")[3])) {
            currIndex = index;
            return true;
          }
          return false;
        });
        // Get the indices of the previous and next.
        let prev = currIndex === 0 ? null : sortedRows[currIndex - 1];
        let next =
          currIndex === sortedRows.length - 1
            ? null
            : sortedRows[currIndex + 1];
        setConsecutiveOpportunity({
          prev: prev,
          next: next,
        });
      }
    } else {
      setInOpportunity(false);
      setShowNavBarDetails(false);
    }
  }, [location]);

  const setToolbarDetails = (opportunity) => {
    // Temporary - this will likely have more information when we add more to the toolbar.
    setOpportunity(opportunity);
  };

  return (
    <div>
      <Toolbar>
        <Stack
          sx={{ flexGrow: 1 }}
          alignItems="center"
          justifyContent="flex-start"
          className={classes.title}
        >
          {displayContext.displayMode !== "iframe" ? (
            <>
              <div
                onClick={() => {
                  history.push("/main/opportunities");
                }}
                className={
                  inOpportunity ? classes.titleButtonInOpp : classes.titleButton
                }
                onMouseEnter={() => {
                  setOppButtonHover(true);
                }}
                onMouseLeave={() => {
                  setOppButtonHover(false);
                }}
              >
                Accounts
              </div>
            </>
          ) : (
            <div
              onClick={() => {
                window.open(rule5AppUrl, "_blank");
              }}
              className={
                inOpportunity ? classes.titleButtonInOpp : classes.titleButton
              }
              onMouseEnter={() => {
                setOppButtonHover(true);
              }}
              onMouseLeave={() => {
                setOppButtonHover(false);
              }}
            >
              <Tooltip arrow title="Open rule5" placement="bottom">
                <img
                  src={rule5logo}
                  alt="logo"
                  style={{ height: "32px", marginTop: "24px" }}
                />
              </Tooltip>
            </div>
          )}

          <Fade
            in={showNavBarDetails && displayContext.displayMode !== "iframe"}
            timeout={300}
          >
            <Stack direction="row" justifyContent="space-between">
              <ChevronRight
                className={classes.chevronBreadcrumb}
                sx={{
                  m: 1,
                  transform: oppButtonHover ? "scaleX(-1)" : "none",
                }}
              />
            </Stack>
          </Fade>
          <Fade
            in={showNavBarDetails && displayContext.displayMode !== "iframe"}
            unmountOnExit
            onEnter={() => {
              setShowOpportunityTools(false);
            }}
            onExited={() => {
              setShowOpportunityTools(true);
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ flexGrow: 1 }}
            >
              {inOpportunity && (
                <React.Fragment>
                  <span
                    style={{
                      transition: "0.3s",
                      opacity: oppButtonHover ? 0 : 1,
                    }}
                  >
                    {" "}
                    {`${opportunity?.Account} - ${opportunity?.Name}`}{" "}
                  </span>
                  <Tooltip title="Back to top" placement="left">
                    <IconButton
                      sx={{ width: "40px", height: "40px" }}
                      onClick={() => {
                        // Enables smooth scrolling, scrolls to top of page, disables smooth scrolling.
                        // (Smooth scroll is not compatible with react-beautiful-dnd)
                        let scrollTarget =
                          document.getElementById("workspaceStyled");
                        scrollTarget.style.scrollBehavior = "smooth";
                        scrollTarget.scrollTop = 0;
                        setTimeout(() => {
                          scrollTarget.style.scrollBehavior = "auto";
                        }, 200);
                      }}
                    >
                      <ArrowUpwardIcon />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              )}
            </Stack>
          </Fade>
          {displayContext.presetCompany.opportunityId &&
            displayContext.presetCompany.companyId &&
            displayContext.presetCompany.functionalArea && (
              <Button
                startIcon={<QuestionAnswerIcon />}
                sx={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  fontSize: "15px",
                  margin: "16px auto",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderRadius: "8px",
                  width: "190px",
                }}
                onClick={() => {
                  // only history push if it's salesforce,
                  // otherwise just update the displayContext.activeTab (new thing i just made up)
                  history.push(
                    `/main/chat?${createUrlSearchParams(
                      displayContext.presetCompany,
                      displayContext.presetCompany.companyId
                    )}&filterToDefault=true`
                  );
                }}
              >
                <Typography
                  sx={{
                    textTransform: "none",
                    fontWeight: 500,
                  }}
                >
                  Switch to Action IQ
                </Typography>
              </Button>
            )}
          <Fade
            in={inOpportunity && showOpportunityTools}
            unmountOnExit
            timeout={{ exit: 0, enter: 200 }}
            sx={{ flexGrow: 1 }}
          >
            <Stack
              sx={{ flexGrow: 1 }}
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              {(consecutiveOpportunity.prev === null &&
                consecutiveOpportunity.next === null) ||
              displayContext.displayMode === "iframe" ? null : (
                <PreviousNextPicker
                  previousTooltip={
                    consecutiveOpportunity.prev !== null
                      ? consecutiveOpportunity.prev?.preview
                      : ""
                  }
                  nextTooltip={
                    consecutiveOpportunity.next !== null
                      ? consecutiveOpportunity.next?.preview
                      : ""
                  }
                  previousDisabled={consecutiveOpportunity.prev === null}
                  nextDisabled={consecutiveOpportunity.next === null}
                  previousClick={() => {
                    history.push(
                      `/main/opportunities/${consecutiveOpportunity.prev.id}/research`
                    );
                  }}
                  nextClick={() => {
                    history.push(
                      `/main/opportunities/${consecutiveOpportunity.next.id}/research`
                    );
                  }}
                />
              )}
              {displayContext.displayMode === "iframe" && (
                <MuiLink
                  sx={{ fontSize: "15px", mr: 3 }}
                  href={`${rule5AppUrl}/main/opportunities/${opportunity?.id}/research`}
                  target="_blank"
                >
                  Open in rule5
                </MuiLink>
              )}
              <Button
                variant="contained"
                disableRipple
                disableElevation
                sx={{
                  textTransform: "none",
                  borderRadius: "6px",
                  pt: 1,
                  mr: "-2px",
                }}
                onClick={() => {
                  dialog.openModal(
                    "Share Opportunity",
                    ShareOpportunity,
                    {
                      specificOpportunity: opportunity,
                      idList: [opportunity.id],
                      opportunityList: [
                        {
                          Account: opportunity.Account,
                          Name: opportunity.Name,
                        },
                      ],
                    },
                    "sm"
                  );
                }}
              >
                Share
                <ShareIcon sx={{ ml: 1, mb: "2px", fontSize: "18px" }} />
              </Button>
            </Stack>
          </Fade>
        </Stack>
      </Toolbar>
      <Fade in={showNavBarDetails}>
        <Divider />
      </Fade>
      <Workspace>
        <OpListContext.Provider
          value={{
            currFilter: listFilter,
            setFilter: setListFilter,
            setSortedRows: setSortedRows,
          }}
        >
          <Switch location={location} className="fade">
            <Route
              exact
              path="/main/opportunities"
              render={() => (
                <OpportunityList
                  paginationModel={paginationModel}
                  setPaginationModel={setPaginationModel}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  totalRowCount={totalRowCount}
                  rows={rows}
                  rowsLoaded={rowsLoaded}
                  loading={loading}
                />
              )}
            />
            <Route
              exact
              path="/main/opportunities/auto"
              render={(props) => <SFLanding {...props} />}
            />
            <Route
              path="/main/opportunities/:id/:workspace"
              render={(props) => (
                <OpportunityWindow
                  setRowsStale={setRowsStale}
                  key={props.match.params.id}
                  setShowNavBarDetails={setShowNavBarDetails}
                  setToolbarDetails={setToolbarDetails}
                  sortedRows={sortedRows}
                  {...props}
                />
              )}
            />
          </Switch>
        </OpListContext.Provider>
      </Workspace>
    </div>
  );
}
