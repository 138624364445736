import React from "react";
import { Box, Button, Stack } from "@mui/material";
import Toolbar from "../../common/Toolbar";
import AccountAgentResults from "./AccountAgentResults";
import Workspace from "../Workspace";
import { ACCOUNT_EVENTS_COLUMNS } from "./common/monitorColumns";
import { useDialog } from "../../context/DialogContext";
import CreateAgent from "../../modal/CreateAgent";
import { accountsAgentType } from "./common/AgentTypes";

export default function AccountEvents(props) {
	const dialog = useDialog();
	const CREATE_TITLE = "Create company agent";

	return (
		<Box sx={{ height: "100vh" }}>
			<Toolbar>
				<Stack direction="row" alignItems="center">
					Company signals
				</Stack>
				<Button
					onClick={() => {
						dialog.openModal(
							CREATE_TITLE,
							CreateAgent,
							{
								agentType: accountsAgentType,
							},
							"lg"
						);
					}}
					variant="contained"
					disableRipple
					disableElevation
					sx={{ alignSelf: "center" }}
				>
					{CREATE_TITLE}
				</Button>
			</Toolbar>
			<Workspace>
				<AccountAgentResults columns={ACCOUNT_EVENTS_COLUMNS} />
			</Workspace>
		</Box>
	);
}
