import React from "react";
import JobChangeLeads from "./JobChangeLeads";
import extensibilitySdk from "@getoutreach/extensibility-sdk";
import Workspace from "../Workspace";
import PeopleAgentResults from "./PeopleAgentResults";
import { JOB_CHANGE_LEADS_COLUMNS } from "./common/monitorColumns";
import Toolbar from "../../common/Toolbar";
import { Box } from "@mui/material";

// Initialize the SDK
// const outreachContext = await extensibilitySdk.init();

export default function ProspectsTile(props) {
  React.useEffect(() => {
    const init = async () => {
      const outreachContext = await extensibilitySdk.init();
      // Handle the outreachContext as needed
      console.log("outreachContext details: ");
      console.log(JSON.stringify(outreachContext));
      setName({
        firstName: outreachContext.prospect?.firstName,
        lastName: outreachContext.prospect.lastName,
      });
    };
    init();
  }, []);

  const [name, setName] = React.useState();
  //   const dialog = useDialog();
  const CREATE_TITLE = "Create people agent";

  return (
    <Box sx={{ height: "100vh" }}>
      <Toolbar>
        {/* <Stack direction="row" alignItems="center"> */}
        People signals
        {/* </Stack> */}
      </Toolbar>
      <Workspace>
        <PeopleAgentResults columns={JOB_CHANGE_LEADS_COLUMNS} name={name} />
      </Workspace>
    </Box>
  );
}
