import React from "react";
import extensibilitySdk from "@getoutreach/extensibility-sdk";
import Workspace from "../Workspace";
import { ACCOUNT_EVENTS_COLUMNS } from "./common/monitorColumns";
import Toolbar from "../../common/Toolbar";
import { Box } from "@mui/material";
import AccountAgentResults from "./AccountAgentResults";
import WSOpportunities from "../WSOpportunities";
import WSChat from "../WSChat";
import { useDisplayContext } from "../../context/DisplayContext";
import axios from "axios";
import { rule5properties } from "../../properties";
import SFLanding from "../opportunity/SFLanding";

// Initialize the SDK
// const outreachContext = await extensibilitySdk.init();

export default function AccountsTab(props) {
  React.useEffect(() => {
    const init = async () => {
      //todo: error handling
      const outreachContext = await extensibilitySdk.init();
      // Handle the outreachContext as needed
      console.log("outreachContext details: ");
      console.log(JSON.stringify(outreachContext));
      setName(outreachContext.account.name);
      // setName(outreachContext.account.domain);
      // if domain not exist then use getCompaniesByName and have user select company if multiple results

      axios
        .get(
          rule5properties.companyInfo +
            "?domain=" +
            outreachContext.account.domain
        )
        .then((res) => {
          setCompanyId(res.data?.companyId);
        });
    };
    init();
  }, []);

  const [name, setName] = React.useState();
  const [companyId, setCompanyId] = React.useState();

  const displayContext = useDisplayContext();

  const accountComponents = {
    accountIq: {
      Component: WSOpportunities, // todo make custom component that uses window if there's only one, or request research if none
      displayName: "Account IQ",
      props: {
        // configuredSlackChannels,
        // setConfiguredSlackChannels,
      },
    },
    actionIq: {
      Component: WSChat,
      displayName: "Action IQ",
      props: {
        // configuredOutreachConnection,
        // setConfiguredOutreachConnection,
      },
    },
    signalIq: {
      Component: AccountAgentResults,
      displayName: "Signal IQ",
      props: {
        columns: ACCOUNT_EVENTS_COLUMNS,
        // setConfiguredOutreachConnection,
      },
    },
  };

  const AccountComponent = accountComponents[displayContext.activeTab] || null;

  console.log("name1: " + name);
  console.log("companyId: " + companyId);

  return (
    <Box sx={{ height: "100vh" }}>
      <Toolbar>
        {/* <Stack direction="row" alignItems="center"> */}
        {name}
        {/* </Stack> */}
      </Toolbar>
      <Workspace>
        {/* this should be displayContext.activeTab */}
        {/* <AccountComponent.Component {...AccountComponent.props} /> */}
        <SFLanding companyName={name} />
      </Workspace>
    </Box>
  );
}
