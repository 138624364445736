import React from "react";
import Workspace from "./Workspace";
import Toolbar from "../common/Toolbar";
import { Avatar, Box, Card, Button, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Image from "../common/Image";
import { pickBy, isBoolean, isObject, omit } from "lodash";
import axios from "axios";
import { rule5properties } from "../properties";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import CustomSnackbar from "../common/CustomSnackbar";
import { getColorFromString, numberAbbrev } from "../common/Utils";
import { Stack } from "@mui/material";
import { useDialog } from "../context/DialogContext";
import { useHistory } from "react-router-dom";
import SearchOpportunities from "../modal/SearchOpportunities";
import { useSearchForm } from "../context/SearchFormContext";
import { LoadedAvatar } from "../common/StyledComponents";

const useStyles = makeStyles(() => ({
  root: {
    border: 0,
    "& .MuiDataGrid-main": {
      marginLeft: "30px",
      marginRight: "30px",
    },
    card: {},
    "& .MuiDataGrid-columnHeaderTitle": {
      overflow: "hidden",
      lineHeight: "20px",
      whiteSpace: "normal",
      fontSize: "14px",
      fontWeight: "400",
    },
    "& .MuiDataGrid-columnsContainer": {
      minHeight: "100px",
    },
    "& .MuiDataGrid-columnHeaderWrapper": {
      height: "56px",
      color: "#999CA0",
      "& .MuiDataGrid-columnHeader": {
        "& .MuiDataGrid-columnHeaderDraggableContainer": {
          "& .MuiDataGrid-menuIcon": {
            "& .MuiButtonBase-root": {
              color: "#999CA0",
            },
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: "left",
            "& .MuiDataGrid-iconButtonContainer": {
              "& .MuiButtonBase-root": {
                color: "#999CA0",
              },
            },
          },
        },
      },
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: "0px",
      "& .MuiTablePagination-root": {
        color: "rgba(0,0,0,0.7)",
        "& .MuiSelect-select": {
          color: "#000000",
          border: "1px solid #F0F0F0",
        },
        "& .MuiSelect-icon": {
          // color: theme.common.selectIconColor,
        },
        "& .MuiTablePagination-actions": {
          "& .MuiButtonBase-root": {
            "& .MuiSvgIcon-root": {
              color: "rgb(25, 118, 210)",
            },
          },
        },
      },
    },
    "& .MuiDataGrid-cell": {
      cursor: "pointer",
      "&:focus": {
        outline: "none",
      },
      maxHeight: "fit-content !important",
      overflow: "hidden",
      whiteSpace: "initial !important",
      display: "flex !important",
      alignItems: "center !important",
      paddingTop: "10px !important",
      paddingBottom: "10px !important",
      fontSize: "1rem",
    },
    "& .MuiDataGrid-row": {
      borderRadius: "10px",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,.01)",
      },
      "&:active": {
        backgroundColor: "rgba(0,0,0,.1)",
      },
    },
    "& .MuiGridPanelWrapper-root": {
      opacity: 0.1,
    },
    "& .MuiButton-root.Mui-disabled": {
      color: "rgba(0,0,0,0.5)",
    },
  },
  toolbarButton: {
    padding: "10px 18px 10px 18px",
    margin: "7px",
    color: "rgba(0,0,0,0.5)",
    borderRadius: "8px",
    textTransform: "none",
  },
}));

export default function WSSearch() {
  const dialog = useDialog();
  const [response, setResponse] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { searchForm, setSearchForm } = useSearchForm();
  const history = useHistory();

  const onSubmit = (data, e) => {
    setLoading(true);
    const nonEmptyFilters = pickBy(
      data,
      (value) =>
        value?.length > 0 || isBoolean(value) || Number.isInteger(value)
    );

    axios
      .post(rule5properties.opptySearch, nonEmptyFilters)
      .catch(function (error) {
        setLoading(false);
        console.log("ERROR: " + JSON.stringify(error));
      })
      .then((response) => {
        setLoading(false);
        if (response?.status === 200) {
          setResponse(response.data);
        } else {
          console.log(
            `Search endpoint response code: ${
              response?.status
            }. Details: ${JSON.stringify(response?.data)}`
          );
        }
      });
  };

  // Remove otherCardTypes because there's another entry named keywordsInOtherCardTypes
  // which is the same filter, so don't want to count it twice.
  const numAppliedFilters = isObject(searchForm)
    ? Object.values(omit(searchForm, "otherCardTypes")).length
    : 0;

  // Open the search dialog on visiting search page.
  React.useEffect(() => {
    if (history.location.pathname !== "/main/search") {
      return;
    }

    onSubmit(searchForm);

    dialog.openModal("Search accounts", SearchOpportunities, {
      onSubmit: onSubmit,
      setResponse: setResponse,
    });
  }, [history.location]); // the location.key gets updated when the "Search" ListItem
  // from MenuList is clicked, even when pathname stays the same ie '/main/search'

  return (
    <div id="focusout">
      <Toolbar>
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            Search
            <Button
              onClick={() =>
                dialog.openModal("Search Opportunities", SearchOpportunities, {
                  onSubmit: onSubmit,
                  setResponse: setResponse,
                })
              }
              variant="contained"
              disableRipple
              disableElevation
              sx={{
                textTransform: "none",
                borderRadius: "6px",
                pt: 1,
                mr: "-2px",
                alignSelf: "center",
                height: "38px",
                width: "110px",
              }}
            >
              {"Filters"}
              {numAppliedFilters > 0 && (
                <Typography
                  style={{
                    border: "1px solid white",
                    borderRadius: "50%",
                    height: "22px",
                    width: "22px",
                    marginLeft: "7px",
                    fontWeight: "500",
                  }}
                  variant="body2"
                >
                  {numAppliedFilters}
                </Typography>
              )}
            </Button>
          </div>
        </>
      </Toolbar>
      <Workspace>
        <SearchResults response={response} loading={loading}></SearchResults>
      </Workspace>
    </div>
  );
}

function SearchResults(props) {
  const theme = useTheme();
  const { response, loading } = props;

  if (!response) {
    return null;
  }

  // User is looking but not finding any results.
  if (!loading && response && response.length === 0) {
    return (
      <Stack alignItems="center" justifyContent="center" sx={{ flexGrow: "1" }}>
        <Typography variant="h5" sx={{ opacity: 0.5, top: "50%" }}>
          No results found.
        </Typography>
      </Stack>
    );
  }

  return (
    <div
      style={{
        minHeight: "500px",
        height: "100%",
        maxWidth: "100%",
        padding: `0px ${theme.workspace.commonPadding}px ${theme.workspace.commonPadding}px ${theme.workspace.commonPadding}px`,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SearchResultsTable {...props} />
    </div>
  );
}

function SearchResultsTable(props) {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const history = useHistory();

  const theme = useTheme();
  const classes = useStyles();

  const { response: rows, loading } = props;

  const [pageSize, setPageSize] = React.useState(10);

  const columns = [
    {
      field: "Account",
      headerName: "Account",
      valueGetter: (params) => params.row.Account,
      width: 300,
      renderCell: (cellValues) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                width: "56px",
                height: "56px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mr: 2,
              }}
            >
              <LoadedAvatar
                source={cellValues.row.companyInfo?.icon}
              >
                {cellValues.row?.Account}
              </LoadedAvatar>
            </Box>
            <Typography sx={{ textTransform: "capitalize" }}>
              {cellValues.row.Account ? cellValues.row.Account : "-"}
            </Typography>
          </div>
        );
      },
    },
    { field: "functionalArea", headerName: "Functional Area", flex: 1 },
    {
      field: "companyOverviewInfo.revenue",
      valueGetter: (params) =>
        params.row.companyOverviewInfo?.revenue
          ? numberAbbrev(
              params.row.companyOverviewInfo?.currency,
              params.row.companyOverviewInfo?.revenue
            )
          : "--",
      headerName: "Revenue",
      flex: 1,
    },
    {
      field: "companyOverviewInfo.marketCap",
      valueGetter: (params) =>
        params.row.companyOverviewInfo?.marketCap
          ? numberAbbrev(
              params.row.companyOverviewInfo?.currency,
              params.row.companyOverviewInfo?.marketCap
            )
          : "--",
      headerName: "Market Cap",
      flex: 1,
    },
    {
      field: "companyOverviewInfo.employeeCount",
      valueGetter: (params) =>
        params.row.companyOverviewInfo?.employeeCount
          ? parseInt(
              params.row.companyOverviewInfo?.employeeCount
            ).toLocaleString()
          : "--",
      headerName: "Number of Employees",
      flex: 1,
    },
    {
      field: "companyOverviewInfo.industry",
      valueGetter: (params) =>
        params.row.companyOverviewInfo?.industry
          ? params.row.companyOverviewInfo?.industry
          : "--",
      headerName: "Industry",
      flex: 1,
    },
  ];

  function CustomToolbar() {
    const dgclasses = useStyles();
    return (
      <div style={{ padding: "0px 7px 10px 7px" }}>
        <GridToolbarContainer>
          <GridToolbarQuickFilter
            className={dgclasses.toolbarButton}
            sx={{ width: "250px" }}
            debounceMs={500}
          />
          <div style={{ flex: 1 }}> </div>
          <GridToolbarExport
            variant="filled"
            color="inherit"
            className={dgclasses.toolbarButton}
            csvOptions={{ allColumns: true }}
          />
        </GridToolbarContainer>
      </div>
    );
  }

  const updatePageSize = (newPageSize) => {
    setPageSize(newPageSize);
  };

  return (
    <Card
      id="card"
      variant="outlined"
      sx={{ display: "flex", flexGrow: "1", pt: 1, borderRadius: "8px" }}
    >
      <div style={{ flexGrow: 1 }}>
        <DataGridPro
          className={classes.root}
          rowHeight={theme.opportunityList.rowHeight}
          rows={rows}
          columns={columns}
          loading={loading}
          pagination={true}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          onPageSizeChange={updatePageSize}
          disableSelectionOnClick
          disableColumnMenu
          onRowClick={(params, event) => {
            history.push(`/main/opportunities/${params.row.id}/research`);
          }}
          components={{ Toolbar: CustomToolbar }}
          componentsProps={{
            toolbar: {
              csvOptions: { allColumns: true },
              printOptions: { allColumns: true },
            },
          }}
        />
      </div>
      <CustomSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </Card>
  );
}
