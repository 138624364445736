import React from "react";
import {
  Box,
  Collapse,
  IconButton,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import parse, { domToReact } from "html-react-parser";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AgentResultButton from "./AgentResultButton";
import RunAgentButton from "./RunAgentButton";
import BadgedIconButton from "./BadgedIconButton";
import EditAgentList from "./EditAgentList";
import SensorsIcon from "@mui/icons-material/Sensors";
import CategoryIcon from "@mui/icons-material/Category";
import BusinessIcon from "@mui/icons-material/Business";
import GroupsIcon from "@mui/icons-material/Groups";
import DeleteIcon from "@mui/icons-material/Delete";
import { accountsAgentType, peopleAgentType } from "./AgentTypes";
import { formatDate } from "../../../common/Utils";
import AgentStatusSwitch from "../AgentStatusSwitch";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ModelTrainingOutlinedIcon from "@mui/icons-material/ModelTrainingOutlined";

export const ACCOUNT_AGENT_COLUMNS = (
  loadAgentRows,
  deleteAgent,
  dialog,
  timeZone,
  updateAgentRows
) => [
  {
    field: "name",
    headerName: "Name",
    flex: 300,
    renderCell: (cellValues) => {
      return (
        <AgentName
          syncResearchAccounts={cellValues.row.syncResearchAccounts}
          name={cellValues.row.name}
        />
      );
    },
  },
  {
    field: "description",
    headerName: "Description",
    flex: 500,
  },
  {
    field: "accounts",
    headerName: "Companies",
    headerAlign: "center",
    align: "center",
    sortable: false,
    flex: 100,
    renderHeader: (params) => (
      <TooltipColumnHeader
        headerText="Companies"
        tooltipText="Specify a list of companies for this agent to monitor."
      />
    ),
    renderCell: (cellValues) => (
      <BadgedIconButton
        loadAgentRows={loadAgentRows}
        type="accounts"
        dialog={dialog}
        cellValues={cellValues}
        icon={<BusinessIcon />}
        onClick={(e) => {
          e.stopPropagation();
          dialog.openModal(
            <AgentName
              syncResearchAccounts={cellValues.row.syncResearchAccounts}
              name={cellValues.row.name}
              prependedText={`Edit or add ${accountsAgentType.displayName} - `}
            />,
            EditAgentList,
            {
              agentType: accountsAgentType,
              modAgent: cellValues.row,
              loadAgentRows: loadAgentRows,
            },
            "lg"
          );
        }}
      />
    ),
  },
  {
    field: "signals",
    headerName: "Signals",
    headerAlign: "center",
    align: "center",
    sortable: false,
    flex: 100,
    renderHeader: (params) => (
      <TooltipColumnHeader
        headerText="Signals"
        tooltipText="Select from a list of provided signal types for agents to look out for."
      />
    ),
    renderCell: (cellValues) => (
      <BadgedIconButton
        loadAgentRows={loadAgentRows}
        type="signals"
        dialog={dialog}
        cellValues={cellValues}
        icon={<SensorsIcon />}
      />
    ),
  },
  {
    field: "actions",
    headerName: "Actions",
    headerAlign: "center",
    align: "center",
    sortable: false,
    flex: 100,
    renderHeader: (params) => (
      <TooltipColumnHeader
        headerText="Actions"
        tooltipText="Configure notifications and actions to trigger when this agent finds new results."
      />
    ),
    renderCell: (cellValues) => (
      <BadgedIconButton
        loadAgentRows={loadAgentRows}
        type="actions"
        dialog={dialog}
        cellValues={cellValues}
        icon={<CategoryIcon />}
      />
    ),
  },
  {
    field: "run",
    headerName: "Run now?",
    headerAlign: "center",
    align: "center",
    sortable: false,
    flex: 200,
    renderCell: (cellValues) => {
      return (
        <Stack alignItems="center" direction="row">
          <RunAgentButton
            type="accounts"
            cellValues={cellValues}
            updateAgentRows={updateAgentRows}
            timeZone={timeZone}
          />
        </Stack>
      );
    },
  },
  {
    field: "results",
    headerName: "Results",
    sortable: false,
    width: 200,
    renderCell: (cellValues) => {
      return (
        <AgentResultButton
          type="accounts"
          resultCount={cellValues.row.accountsResultCount}
          agentId={cellValues.row.agentId}
        />
      );
    },
  },
  {
    field: "status",
    headerName: "Active?",
    width: 70,
    renderCell: (cellValues) => {
      return (
        <AgentStatusSwitch
          cellValues={cellValues}
          loadAgentRows={loadAgentRows}
        />
      );
    },
  },
  {
    field: "Actions",
    type: "actions",
    width: 50,
    getActions: ({ row }) => [
      <GridActionsCellItem
        icon={<DeleteIcon />}
        onClick={() => deleteAgent(row)}
        label="Delete"
      />,
    ],
  },
];

export const ACCOUNT_AGENT_RESULT_COLUMNS = [
  {
    field: "companyName",
    headerName: "Company",
    flex: 200,
  },
  {
    field: "signal",
    headerName: "Signal",
    flex: 300,
  },
  {
    field: "publishedDate",
    headerName: "Date",
    flex: 200,
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
    renderCell: (cellValues) =>
      formatDate(new Date(cellValues.row.publishedDate)),
  },
  {
    field: "title",
    headerName: "Title",
    flex: 400,
    renderCell: (cellValues) => {
      return <ClampedCellValue text={cellValues.row.title} lineLimit={2} />;
    },
  },
  {
    field: "summary",
    headerName: "Summary",
    flex: 500,
    renderCell: (cellValues) => (
      <TruncatedResultSummary cellValues={cellValues} />
    ),
  },
  {
    field: "link",
    headerName: "Source",
    sortable: false,
    flex: 100,
    headerAlign: "center",
    align: "center",
    renderCell: (cellValues) => (
      <Tooltip
        title={
          cellValues.row.link.substring(
            0,
            Math.min(cellValues.row.link.length - 1, 32)
          ) + "..."
        }
      >
        <IconButton
          onClick={() => {
            window.open(cellValues.row.link);
          }}
        >
          <OpenInNewIcon />
        </IconButton>
      </Tooltip>
    ),
  },
];

export const ACCOUNT_EVENTS_COLUMNS = [
  ...ACCOUNT_AGENT_RESULT_COLUMNS,
  {
    field: "agentName",
    headerName: "Agent",
    flex: 300,
  },
];

export const PEOPLE_AGENT_COLUMNS = (
  loadAgentRows,
  deleteAgent,
  dialog,
  timeZone,
  updateAgentRows
) => [
  {
    field: "name",
    headerName: "Name",
    flex: 300,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 500,
  },
  {
    field: "people",
    headerName: "People",
    headerAlign: "center",
    align: "center",
    sortable: false,
    flex: 100,
    renderHeader: (params) => (
      <TooltipColumnHeader
        headerText="People"
        tooltipText="Specify a list of people, leads, or fans for this agent to monitor."
      />
    ),
    renderCell: (cellValues) => (
      <BadgedIconButton
        type="people"
        dialog={dialog}
        cellValues={cellValues}
        icon={<GroupsIcon />}
        onClick={(e) => {
          e.stopPropagation();
          dialog.openModal(
            `Edit or add ${peopleAgentType.displayName}  - ${cellValues.row.name}`,
            EditAgentList,
            {
              agentType: peopleAgentType,
              modAgent: cellValues.row,
              loadAgentRows: loadAgentRows,
            },
            "xl"
          );
        }}
      />
    ),
  },
  {
    field: "actions",
    headerName: "Actions",
    headerAlign: "center",
    align: "center",
    sortable: false,
    flex: 100,
    renderHeader: (params) => (
      <TooltipColumnHeader
        headerText="Actions"
        tooltipText="Configure notifications and actions to trigger when this agent finds new results."
      />
    ),
    renderCell: (cellValues) => (
      <BadgedIconButton
        loadAgentRows={loadAgentRows}
        type="actions"
        dialog={dialog}
        cellValues={cellValues}
        icon={<CategoryIcon />}
      />
    ),
  },
  {
    field: "run",
    headerName: "Run now?",
    headerAlign: "center",
    align: "center",
    sortable: false,
    flex: 200,
    renderCell: (cellValues) => {
      return (
        <Stack alignItems="center" direction="row">
          <RunAgentButton
            type="people"
            cellValues={cellValues}
            updateAgentRows={updateAgentRows}
            timeZone={timeZone}
          />
        </Stack>
      );
    },
  },
  {
    field: "results",
    headerName: "Results",
    width: 200,
    renderCell: (cellValues) => {
      return (
        <AgentResultButton
          type="people"
          resultCount={cellValues.row.peopleResultCount}
          agentId={cellValues.row.agentId}
        />
      );
    },
  },
  {
    field: "status",
    headerName: "Active?",
    width: 70,
    renderCell: (cellValues) => {
      return <AgentStatusSwitch cellValues={cellValues} />;
    },
  },
  {
    field: "Actions",
    type: "actions",
    width: 50,
    getActions: ({ row }) => [
      <GridActionsCellItem
        icon={<DeleteIcon />}
        onClick={() => deleteAgent(row)}
        label="Delete"
      />,
    ],
  },
];

export const PEOPLE_AGENT_RESULT_COLUMNS = [
  {
    field: "linkedInProfileUrl",
    headerName: "",
    headerAlign: "center",
    align: "center",
    sortable: false,
    renderCell: (cellValues) => {
      return (
        <IconButton
          onClick={() => {
            window.open(cellValues.row.linkedinProfileUrl, "_blank");
          }}
        >
          <LinkedInIcon />
        </IconButton>
      );
    },
    flex: 120,
  },
  {
    field: "firstName",
    headerName: "First name",
    flex: 200,
  },
  {
    field: "lastName",
    headerName: "Last name",
    flex: 200,
  },
  {
    field: "oldCompanyTitle",
    headerName: "Previous title",
    flex: 300,
    renderCell: (cellValues) => {
      return (
        <ClampedCellValue text={cellValues.row.oldCompanyTitle} lineLimit={2} />
      );
    },
  },
  {
    field: "oldCompanyName",
    headerName: "Previous company",
    flex: 300,
  },
  {
    field: "currentCompanyTitle",
    headerName: "Current title",
    flex: 300,
    renderCell: (cellValues) => {
      return (
        <ClampedCellValue
          text={cellValues.row.currentCompanyTitle}
          lineLimit={2}
        />
      );
    },
  },
  {
    field: "currentCompanyName",
    headerName: "Current company",
    flex: 300,
  },
  {
    field: "currentCompanyStartDate",
    headerName: "Started",
    flex: 200,
  },
  {
    field: "leadCreationDate",
    headerName: "Created",
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
    renderCell: (cellValues) =>
      formatDate(new Date(cellValues.row.leadCreationDate)),
    flex: 200,
  },
];

export const JOB_CHANGE_LEADS_COLUMNS = [
  ...PEOPLE_AGENT_RESULT_COLUMNS,
  {
    field: "agentName",
    headerName: "Agent",
    flex: 300,
  },
];

function TooltipColumnHeader(props) {
  const { headerText, tooltipText } = props;
  return (
    <Tooltip title={tooltipText} placement="top" arrow>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "14px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {headerText}
      </Typography>
    </Tooltip>
  );
}

function ClampedCellValue(props) {
  const { text, lineLimit } = props;
  return (
    <Tooltip title={text}>
      <Box
        style={{
          overflow: "hidden",
          display: "-webkit-box",
          WebkitLineClamp: lineLimit,
          WebkitBoxOrient: "vertical",
        }}
      >
        {text}
      </Box>
    </Tooltip>
  );
}

function TruncatedResultSummary(props) {
  const { cellValues } = props;
  // console.log(cellValues);
  return (
    <Box
      style={{
        margin: "16px 0px",
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
      }}
    >
      {typeof cellValues.row.summary === "string"
        ? parse(cellValues.row.summary, {
            replace(domNode) {
              if (domNode.name === "ol") {
                return (
                  <ol style={{ margin: 0 }}>{domToReact(domNode.children)}</ol>
                );
              }
              if (domNode.name === "ul") {
                return (
                  <ul style={{ margin: 0 }}>{domToReact(domNode.children)}</ul>
                );
              }
            },
          })
        : "Unable to display summary preview."}
    </Box>
  );
}

export function AgentName(props) {
  const { syncResearchAccounts, name, prependedText } = props;

  return (
    <>
      {prependedText}
      {syncResearchAccounts ? (
        <Tooltip title="The companies in this agent are synced automatically from Account IQ.">
          {name}
          <ModelTrainingOutlinedIcon
            sx={{ ml: 0.5 }}
            fontSize="small"
            htmlColor="#1976d2"
          />
        </Tooltip>
      ) : (
         name 
      )}
    </>
  );
}
