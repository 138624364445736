import { CircularProgress, Fade, IconButton, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useUser } from "../context/UserContext";
import axios from "axios";
import { rule5properties } from "../properties";
import { SlackIntegration } from "./integrations/SlackIntegration";
import { useDialog } from "../context/DialogContext";
import TileButton from "../common/TileButton";
import slackLogo from "../res/slack_button.svg";
import outreachLogo from "../res/outreach_logo.webp";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { OutreachIntegration } from "./integrations/OutreachIntegration";

export default function Integrations(props) {
  const location = useLocation();
  const userContext = useUser();
  const dialog = useDialog();

  const [loaded, setLoaded] = React.useState(false);
  const [configuredSlackChannels, setConfiguredSlackChannels] =
    React.useState(null);
  const [configuredOutreachConnection, setConfiguredOutreachConnection] =
    React.useState(null);
  const [activeTile, setActiveTile] = React.useState(null);

  function fetchSlackConfig() {
    axios
      .get(rule5properties.userMessageChannels + "?system=Slack")
      .then((res) => {
        if (res.status === 200) {
          setConfiguredSlackChannels(res.data);
          return res.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function fetchOutreachConfig() {
    axios
      .get(rule5properties.outreachConnections)
      .then((res) => {
        if (res.status === 200) {
          setConfiguredOutreachConnection(res.data);
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // Check for existing slack config / setup
  useEffect(() => {
    Promise.all([fetchSlackConfig(), fetchOutreachConfig()])
      .then(([slackData, outreachData]) => {
        setLoaded(true);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }, []);

  const integrationComponents = {
    slack: {
      Component: SlackIntegration,
      props: {
        configuredSlackChannels,
        setConfiguredSlackChannels,
      },
    },
    outreach: {
      Component: OutreachIntegration,
      props: {
        configuredOutreachConnection,
        setConfiguredOutreachConnection,
      },
    },
  };

  const IntegrationComponent = integrationComponents[activeTile] || null;

  // integration code is present, perform integration setup
  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    if (
      searchParams.get("code") &&
      searchParams.get("state") === userContext.email
    ) {
      // An authorization code is present and email state match, call Slack callback.
      const params = { code: searchParams.get("code") };
      dialog.openModal(
        "",
        () => (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        ),
        { fixed: true },
        "sm"
      );
      axios
        .post(rule5properties.slackCallback, params)
        .then((res) => {
          if (res.status === 200) {
            fetchSlackConfig();
            dialog.closeModal();
          } else {
            dialog.closeModal();
            console.log("Failed to set up integration.");
            console.log(res);
          }
        })
        .catch((err) => {
          dialog.closeModal();
          console.log("Failed to set up integration:");
          console.log(err);
        });
    }
  }, [location.search, userContext.email]);

  console.log("activeTile=" + activeTile);
  return (
    <Fade in={loaded}>
      {activeTile ? (
        // <Fade in={activeTile}> doesn't work
        <Stack
          sx={{
            backgroundColor: "#fafafa",
            borderRadius: "12px",
            width: "600px",
            height: "50vh",
            padding: "20px",
            gap: "20px",
          }}
        >
          <IconButton
            aria-label="delete"
            sx={{ width: "40px" }}
            onClick={() => {
              setActiveTile(null);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <IntegrationComponent.Component {...IntegrationComponent.props} />
        </Stack>
      ) : (
        // </Fade>
        <Stack
          direction="row"
          sx={{ width: "500px", flexGrow: 1, gap: "20px" }}
        >
          <TileButton
            onClick={() => {
              setActiveTile("slack");
            }}
            image={slackLogo}
            title="Slack"
            description="Configure channels and messages"
          ></TileButton>
          <TileButton
            onClick={() => {
              setActiveTile("outreach");
            }}
            image={outreachLogo}
            title="Outreach"
            description="Configure settings"
          ></TileButton>
          {/* <SlackIntegration
          configuredSlackChannels={configuredSlackChannels}
          setConfiguredSlackChannels={setConfiguredSlackChannels}
        /> */}
        </Stack>
      )}
    </Fade>
  );
}
