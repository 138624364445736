import React from "react";
import axios from "axios";
import { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Researchcard from "./Researchcard";
import Typography from "@mui/material/Typography";
import { rule5properties } from "../../../../properties";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSnackbar from "../../../../common/CustomSnackbar";
import { convertToEditorState } from "./SingleRteCard";
import RichTextReader from "../../../../common/RichTextReader";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
  },
  flexContent: {
    textAlign: "left",
    flexGrow: 1,
    position: "relative",
    margin: "10px",
    width: 320,
    "&:hover button": {
      display: "inherit",
    },
  },
}));

export default function AnnualReports(props) {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const previewData = props.previewData;
  const [annualReportsData, setAnnualReportsData] = React.useState(null);

  useEffect(() => {
    if (previewData) {
      setAnnualReportsData(previewData);
    } else {
      // Call API on initial load
      let parameter = "/" + props.cardId;
      axios
        .get(rule5properties.getCard + parameter)
        .then((response) => {
          let rawdata = response.data;
          if (rawdata.date) {
            // Validate content
            setAnnualReportsData(rawdata);
          } else {
            // API call failed
            setSnackbarMessage(
              "There was a problem with the " + props.cardType + " Card"
            );
            setOpenSnackbar(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.status);
            console.log(error.response.data);
            setSnackbarMessage(
              "There was a problem with the " + props.cardType + " Card"
            );
            setOpenSnackbar(true);
          }
        });
    }
  }, [previewData]);

  if (!annualReportsData) {
    return (
      <Researchcard
        dragHandleProps={{ ...props.dragHandleProps }}
        toggleExpanded={props.toggleExpanded}
        expanded={props.expanded}
        expandHandler={true}
        opsId={props.opportunity.id}
        cardId={props.cardId}
        title={props.cardType}
        closeCard={props.closeCard}
        viewed={props.viewed}
      >
        <div style={{ padding: 30 }}>
          {snackbarMessage === "" && <CircularProgress size={50} />}
          {snackbarMessage !== "" && snackbarMessage}
        </div>
        <CustomSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMessage={snackbarMessage}
        />
      </Researchcard>
    );
  }
  return (
    <Researchcard
      dragHandleProps={{ ...props.dragHandleProps }}
      toggleExpanded={props.toggleExpanded}
      expanded={props.expanded}
      expandHandler={true}
      opsId={props.opportunity.id}
      cardId={props.cardId}
      title={annualReportsData.Title}
      closeCard={props.closeCard}
      viewed={props.viewed}
    >
      {annualReportsData.date && annualReportsData.url && (
        <a
          style={{
            textAlign: "left",
            color: "#525252",
            marginBottom: "20px",
            display: "block",
          }}
          href={annualReportsData.url}
          rel="noreferrer"
          target="_blank"
        >{`FY ${new Date(annualReportsData.date).getFullYear()}`}</a>
      )}
      {!isEmpty(annualReportsData.business) && (
        <>
          <Typography
            textAlign="left"
            variant="body1"
            fontSize="18px"
            color="#525252"
            marginBottom="15px"
          >
            Business
          </Typography>
          {annualReportsData.business.map((business, index) => (
            <QuestionAndAnswerContent
              item={business}
              key={index}
            ></QuestionAndAnswerContent>
          ))}
        </>
      )}
      {!isEmpty(annualReportsData.mda) && (
        <>
          <Typography
            textAlign="left"
            variant="body1"
            fontSize="18px"
            color="#525252"
            marginBottom="15px"
            marginTop="15px"
          >
            Management's Discussion & Analysis
          </Typography>
          {annualReportsData.mda.map((mda, index) => (
            <QuestionAndAnswerContent
              item={mda}
              key={index}
            ></QuestionAndAnswerContent>
          ))}
        </>
      )}
      <CustomSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </Researchcard>
  );
}

export function QuestionAndAnswerContent(props) {
  const { item } = props;
  const classes = useStyles();

  return (
    <div className={classes.flexContainer}>
      <div className={classes.flexContent}>
        <Typography
          variant="body1"
          fontSize="16px"
          color="#525252"
          marginBottom="10px"
          fontWeight="600"
        >
          {item.question}
        </Typography>
        <RichTextReader
          editorState={convertToEditorState(item.answer)}
          fontSize={".850rem"}
        />
      </div>
    </div>
  );
}
