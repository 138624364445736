import React, { useContext } from "react";
import { useEffect } from "react";
import { inIframe } from "../common/Utils";

/* Provides context that allows any children to determine the display context of the app, without having to check URL
 ** explicitly each time. Used for Salesforce iframe display modes. */

const DisplayContext = React.createContext();

export function useDisplayContext() {
  return useContext(DisplayContext);
}

// Create URLSearchParams string for a given opportunity
export function createUrlSearchParams(opp, companyId) {
  let newUrlSearchParams = new URLSearchParams();
  newUrlSearchParams.append("display", "iframe");
  newUrlSearchParams.append("companyId", companyId);
  newUrlSearchParams.append("functionalArea", opp.functionalArea);
  newUrlSearchParams.append("opportunityId", opp.id || opp.opportunityId);
  return newUrlSearchParams.toString();
}

export function DisplayContextProvider({ children }) {
  const [displayMode, setDisplayMode] = React.useState(null);
  const [activeTab, setActiveTab] = React.useState("accountIq");
  // When SFDC landing provides company ID, functional area, or opportunity ID

  let params = new URLSearchParams(document.location.search);
  const [presetCompany, setPresetCompany] = React.useState({
    companyId: params.get("companyId"),
    functionalArea: params.get("functionalArea"),
    opportunityId: params.get("opportunityId"),
  });

  let displayModeParam = params.get("display");

  // Set the display mode once. Should not be changed after.
  //todo maybe custom set it in accountstab
  useEffect(() => {
    setDisplayMode(displayModeParam);
  }, []);

  const contextValue = {
    displayMode,
    presetCompany,
    setPresetCompany,
    activeTab,
    setActiveTab,
  };

  return (
    <DisplayContext.Provider value={contextValue}>
      {children}
    </DisplayContext.Provider>
  );
}
