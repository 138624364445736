import { ButtonBase, Typography } from "@mui/material";
import { styled } from "@mui/styles";

const TileButtonBase = styled(ButtonBase)(({ theme }) => ({
  // display: "flex",
  // flexDirection: "column",
  // height: 200,
  // width: 300,
  // borderRadius: "12px",
  // backgroundColor: "rgb(242, 243, 242)",
  // [theme.breakpoints.down('sm')]: {
  //   width: '100% !important', // Overrides inline-style
  //   height: 100,
  // },
  // "&:hover": {
  //   zIndex: 1,
  //   backgroundColor: "rgb(232, 241, 250)",
  //   // '& .MuiImageBackdrop-root': {
  //   //   opacity: 0.15,
  //   // },
  //   // '& .MuiImageMarked-root': {
  //   //   opacity: 0,
  //   // },
  //   // '& .MuiTypography-root': {
  //   //   border: '4px solid currentColor',
  //   // },
  // },
  height: 200,
  width: 300,
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  justifyContent: "center",
  // alignItems: "start",
  alignItems: "center",
  backgroundColor: "rgb(227 239 248)",
  border: "none",
  borderRadius: "12px",
  outline: "none",
  padding: "15px",
  // height: "100%",
  minHeight: "150px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#ddebf7",
  },
  "&:focus": {
    backgroundColor: "#ddebf7",
  },
}));

export default function TileButton(props) {
  const { title, description, image } = props;
  return (
    <TileButtonBase focusRipple {...props}>
      <img
        src={image}
        alt={`${title} logo`}
        style={{
          height: "40px",
          width: "40px",
          // alignSelf: "start",
          // justifySelf: "start",
        }}
      />
      <Typography fontWeight={600}>{title}</Typography>
      <Typography fontWeight={300}>{description}</Typography>
    </TileButtonBase>
  );
}
